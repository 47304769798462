<!-- analysisYearReport -->
<template>
  <div class="analysisYearReport-box" v-loading="loading">
    <analysisYearReport @loadingChange="loadingChange"></analysisYearReport>
  </div>
</template>

<script>
import analysisYearReport from '@comp/report/analysisYearReport'
export default {
  name: 'AnalysisYearReport',
  data() {
    return {
      loading: true
    }
  },
  props: {},
  components: { analysisYearReport },
  computed: {},
  mounted() {},
  methods: {
    loadingChange(status) {
      this.loading = status
    }
  }
}
</script>

<style lang="scss" scoped></style>
